<template>
  <div>

    <v-card>
      <v-card-text>
        <!-- <v-row v-if="grid.detalhes.items.length > 0"> -->
        <v-row>
          <v-col cols="12" class="px-6">
              <!-- hide-default-footer -->
            <v-data-table
              dense
              color="#018656"
              :headers="grid.detalhes.cabecalho"
              :items="grid.detalhes.items"
              :hide-default-footer="true"
              :items-per-page="50"
              class="elevation-1"
              :loading="grid.detalhes.carregando"
            >
              <template #item.endereco_retirada="{ item }">
                {{ formatarRetirada(item.endereco_retirada) }}
              </template>
              <template #item.data_hora="{ item }">
                {{ formatarDataBR(item.data) }} {{ item.hora }}
              </template>
              <template #item.origem_cidade_uf_local="{ item }">
                {{ item.cidade }} {{ item.cidade != null && item.uf != null ? "/" : "" }} {{ item.uf }} {{ item.local != null && item.uf != null ? "-" : "" }} {{ item.local }} {{"/"}} {{ item.destino_cidade }} {{ item.destino_cidade != null && item.destino_uf != null ? "/" : "" }} {{ item.destino_uf }} {{ item.destino_local != null && item.destino_uf != null ? "-" : "" }} {{ item.destino_local }}
              </template >
              <!-- <template #item.destino_cidade_uf_local="{ item }">
                {{ item.destino_cidade }} {{ item.destino_cidade != null && item.destino_uf != null ? "/" : "" }} {{ item.destino_uf }} {{ item.destino_local != null && item.destino_uf != null ? "-" : "" }} {{ item.destino_local }}
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";

export default {
	name: "Rastreio",
  props: {
    nroobjeto: { type: String },
  },
  data: () => ({
    codigo_empresa: '',
    grid: {
      detalhes: {
        cabecalho: [
          { text: "Codigo", value: "codigo", sortable: false },
          { text: "Status", value: "descricao", sortable: false },
          { text: "Tipo", value: "tipostatus", sortable: false },
          { text: "Slug", value: "slug", sortable: false },
          { text: "Time Line", value: "time_line", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem/Destino", value: "origem_cidade_uf_local", sortable: false },
          { text: "Retirada", value: "endereco_retirada", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
    },
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
// console.log( "mounted this.nroobjeto", this.nroobjeto )
// console.log( this.cadastro.objetoAtual )
    await this.carregarRastreioObjetos(this.nroobjeto);
  },
  methods: {
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatarDataBR(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },
    formatarRetirada(value) {
      if (value) {
        const stringJson = JSON.parse(value);
        return `${stringJson.logradouro}, ${stringJson.numero}, ${stringJson.bairro}, ${stringJson.cep}, ${stringJson.localidade}-${stringJson.uf}`;
      }
    },
    salvarProtocoloReclamacao(nroPi) {
      if (nroPi != "") {
        alert("Gravar protocolo para o objeto " + this.objetoAtual);
      }
    },
    async carregarRastreioObjetos(numero_rastreio) {
      this.grid.detalhes.items = [];
      this.grid.detalhes.carregando = true;
      // let url = `${baseApiUrl}/rastreios?numero_rastreio=${numero_rastreio}`;
      let url = `${baseApiUrl}/rastreios`;
      url += `?cod_empresa=${this.codigo_empresa}`;
      url += `&numero_rastreio=${numero_rastreio}`;

      axios
        .get(url)
        .then((res) => {
          this.grid.detalhes.items = res.data.dados;
        })
        .finally(() => (this.grid.detalhes.carregando = false));
    },
  },

  watch: {
    nroobjeto(val) {
      this.carregarRastreioObjetos(val)
    }
  },
};
</script>

<style>
</style>